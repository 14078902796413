import Grid from "@mui/material/Unstable_Grid2";

function GoogleMapEmbed() {
  return (
    <div>
      <h2>Serving the Entire Tricounty Region of South Carolina</h2>
      <hr />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d428747.6902535128!2d-80.32572201414412!3d32.90897167465177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ba33b6541ae3d95%3A0x27cf4515cefef9b9!2sOn%20Scene%20Disaster%20Restoration!5e0!3m2!1sen!2sus!4v1715299749277!5m2!1sen!2sus"
            width="70%"
            height="450"
            className="google-map"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="Map-Contact">
            <h3>Dexter Decell</h3>
            <a href="mailto:onscenedisaster@gmail.com" className="Banner-no-style-anchor">
              <p>onscenedisaster@gmail.com</p>
            </a>
            <a href="tel:+18434521638" className="Banner-no-style-anchor">
              <p>(843) 452-1638</p>
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default GoogleMapEmbed;
