import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  cursor: "pointer",
}));

function Card({ imageSrc, label, onClick }) {
  return (
    <Grid item xs={12} sm={3}>
      <Item className="Card-container" onClick={onClick}>
        <div className="Card-content">
          <img src={imageSrc} className="Card-img" alt={label} />
          <div className="Card-label">{label}</div>
        </div>
      </Item>
    </Grid>
  );
}

export default Card;
