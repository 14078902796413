import React, { useState, useEffect } from "react";
import logo from "./images/On Scene Disaster Restorations LOGO Final Vectors-01.svg";
import { Button } from "@mui/material";
import DialogContactUsForm from "./DialogContactUsForm";

function AppHeader() {
  // Start Dialog Contact Us Form
  const [open, setOpen] = React.useState(false); // State to control the dialog

  const handleClickOpen = () => {
    setOpen(true); // Handler to open the dialog
  };

  const handleClose = () => {
    setOpen(false); // Handler to close the dialog
  };

  // End Dialog Contact Us Form

  // Start Adding sticky header
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Set true if scrolled more than 50px, false otherwise
      setIsScrolled(window.scrollY > 0);
    };

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // End Adding sticky header
  return (
    <header className={`App-header ${isScrolled ? "scrolled" : ""}`}>
      <div className="App-header-spacer"></div> {/* Left spacer */}
      <div className="App-logo-container">
        <a href="#Top" className="App-logo-link">
          <img src={logo} className="App-logo" alt="logo" />
        </a>
      </div>
      {/* Navigation links for in-page navigation */}
      <nav className="App-nav">
        <ul>
          {/* Anchor tags pointing to elements with corresponding IDs on the page */}
          <li>
            <Button onClick={handleClickOpen} href="#">
              Contact Us
            </Button>
          </li>
          <li>
            <Button href="tel:+18434521638">(843) 452-1638</Button>
          </li>
        </ul>
      </nav>
      <DialogContactUsForm open={open} handleClose={handleClose} />
    </header>
  );
}
export default AppHeader;
