import React from "react";
import { Box, Typography, Grid } from "@mui/material";

// TimelineItem component
const TimelineItem = ({ title, description, imageSrc, isOdd }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6} order={isOdd ? 2 : 1}>
        <Box
          component="img"
          src={imageSrc}
          sx={{
            width: "50%",
            height: "auto",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} order={isOdd ? 1 : 2}>
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: "bold" }}>
            <strong style={{ color: "#d60000" }}>{title}</strong>
          </Typography>
          <Typography>{description}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TimelineItem;
