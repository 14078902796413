import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import StarIcon from "@mui/icons-material/Star";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Card1x3({ text, label }) {
  return (
    <Grid item xs={12} sm={4}>
      <Item>
        <Grid container>
          <Grid item xs={12}>
            <div className="Testimony-Card-Quotes">
              <FormatQuoteIcon style={{ transform: "scaleX(-1)", color: "black" }} />
            </div>
            <p>{text}</p>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StarIcon style={{ transform: "scaleX(-1)", color: "#f5c002" }} />
              <StarIcon style={{ transform: "scaleX(-1)", color: "#f5c002" }} />
              <StarIcon style={{ transform: "scaleX(-1)", color: "#f5c002" }} />
              <StarIcon style={{ transform: "scaleX(-1)", color: "#f5c002" }} />
              <StarIcon style={{ transform: "scaleX(-1)", color: "#f5c002" }} />
            </div>
            <div className="Testimony-Card-Name">{label}</div>
          </Grid>
        </Grid>
      </Item>
    </Grid>
  );
}

export default Card1x3;
