import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const ContactForm = () => {
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const capitalizeWords = (str) => {
    return str.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    // Get the user_name input element
    const userNameInput = e.target.elements.user_name;

    // Capitalize the first letters of the first and last name
    userNameInput.value = capitalizeWords(userNameInput.value);

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
      (result) => {
        console.log(result.text);
        alert("Message Sent Successfully");
      },
      (error) => {
        console.log(error.text);
        alert("Something went wrong!");
      }
    );
    e.target.reset();
    setCaptchaVerified(false);
  };

  const onCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  return (
    <div className="formWrapper">
      <form className="formContainer" onSubmit={handleOnSubmit}>
        <h2>Contact Us</h2>
        <div className="formElement">
          <label className="label" htmlFor="user_name">
            Full Name
          </label>
          <input
            className="input"
            type="text"
            id="user_name"
            name="user_name"
            placeholder="Full Name *"
            required
          />
        </div>
        <div className="formElement">
          <label className="label" htmlFor="user_email">
            Email
          </label>
          <input
            className="input"
            type="email"
            id="user_email"
            name="user_email"
            placeholder="Email *"
            required
          />
        </div>
        <div className="formElement">
          <label className="label" htmlFor="user_phone">
            Phone Number
          </label>
          <input
            className="input"
            type="tel"
            id="user_phone"
            name="user_phone"
            placeholder="Phone Number *"
            required
          />
        </div>
        <div className="formElement">
          <label className="label" htmlFor="user_address">
            Address
          </label>
          <input
            className="input"
            type="text"
            id="user_address"
            name="user_address"
            placeholder="Address *"
            required
          />
        </div>
        <div className="formElement">
          <label className="label" htmlFor="message">
            Message
          </label>
          <textarea
            className="textarea"
            id="message"
            name="message"
            placeholder="Message *"
            required
          />
        </div>
        <div className="recaptcha-container">
          <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={onCaptchaChange} />
        </div>
        <button className="Contact-button" type="submit" disabled={!captchaVerified}>
          Send
        </button>
        <p>🔒 We respect your privacy and do not share your information.</p>
      </form>
    </div>
  );
};

export default ContactForm;
