import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import emailjs from "@emailjs/browser";

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export default function DialogContactUsForm({ open, handleClose }) {
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const onCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };

  const sendEmail = (form) => {
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form, PUBLIC_KEY).then(
      (result) => {
        console.log("Email successfully sent!", result.text);
        alert("Your message has been sent successfully.");
      },
      (error) => {
        console.log("Failed to send email.", error.text);
        alert("Failed to send your message. Please try again later.");
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();

          // Get the user_name input element
          const userNameInput = event.target.elements.user_name;

          // Capitalize the first letters of the first and last name
          userNameInput.value = capitalizeWords(userNameInput.value);

          if (!captchaVerified) {
            alert("Please validate the reCAPTCHA.");
            return;
          }
          sendEmail(event.currentTarget);
          handleClose();
        },
      }}
    >
      <DialogTitle>Contact Us</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Submit your contact info through our contact form. We’ll follow up with a phone
          consultation once we receive these details from you!
        </DialogContentText>
        <hr />
        <TextField
          autoFocus
          required
          margin="dense"
          id="fullName"
          name="user_name"
          label="Full Name"
          type="text"
          fullWidth
          variant="outlined"
          placeholder="Full Name"
        />
        <TextField
          required
          margin="dense"
          id="email"
          name="user_email"
          label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
          placeholder="Email Address"
        />
        <TextField
          required
          margin="dense"
          id="phoneNumber"
          name="user_phone"
          label="Phone Number"
          type="tel"
          fullWidth
          variant="outlined"
          placeholder="Phone Number"
        />
        <TextField
          required
          margin="dense"
          id="address"
          name="user_address"
          label="Street Address"
          type="text"
          fullWidth
          variant="outlined"
          placeholder="Street Address"
        />
        <TextField
          required
          margin="dense"
          id="message"
          name="message"
          label="Message"
          type="text"
          fullWidth
          variant="outlined"
          placeholder="Your message.."
        />
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={onCaptchaChange} />
        </div>
        <p style={{ display: "flex", justifyContent: "center" }}>
          🔒 We respect your privacy and do not share your information.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" disabled={!captchaVerified}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// import * as React from "react";
// import { useState } from "react";
// import TextField from "@mui/material/TextField";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import { Button } from "@mui/material";
// import ReCAPTCHA from "react-google-recaptcha";
// import emailjs from "@emailjs/browser";

// const SERVICE_ID = "service_k1tq1vf";
// const TEMPLATE_ID = "template_w54wwn9";
// const PUBLIC_KEY = "6VEGfFlNYIz0GWtXm";
// const RECAPTCHA_SITE_KEY = "6Lf-IoIpAAAAALZd3Zx9AZkTZgEV7lO1_MKvaKC2";

// export default function DialogContactUsForm({ open, handleClose }) {
//   const [captchaVerified, setCaptchaVerified] = useState(false);

//   const capitalizeWords = (str) => {
//     return str.replace(/\b(\w)/g, (s) => s.toUpperCase());
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Get the user_name input element
//     const userNameInput = e.target.elements.user_name;

//     // Capitalize the first letters of the first and last name
//     userNameInput.value = capitalizeWords(userNameInput.value);

//     emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
//       (result) => {
//         console.log(result.text);
//         alert("Message Sent Successfully");
//       },
//       (error) => {
//         console.log(error.text);
//         alert("Something went wrong!");
//       }
//     );
//     e.target.reset();
//     setCaptchaVerified(false);
//     handleClose();
//   };

//   const onCaptchaChange = (value) => {
//     setCaptchaVerified(!!value);
//   };

//   return (
//     <Dialog open={open} onClose={handleClose}>
//       <DialogTitle>Contact Us</DialogTitle>
//       <DialogContent component="form" onSubmit={handleSubmit}>
//         <DialogContentText>
//           Submit your contact info through our contact form. We’ll follow up with a phone
//           consultation once we receive these details from you!
//         </DialogContentText>
//         <hr />
//         <TextField
//           autoFocus
//           required
//           margin="dense"
//           id="fullName"
//           name="user_name"
//           label="Full Name"
//           type="text"
//           fullWidth
//           variant="outlined"
//           placeholder="Full Name"
//         />
//         <TextField
//           required
//           margin="dense"
//           id="email"
//           name="user_email"
//           label="Email Address"
//           type="email"
//           fullWidth
//           variant="outlined"
//           placeholder="Email Address"
//         />
//         <TextField
//           required
//           margin="dense"
//           id="phoneNumber"
//           name="user_phone"
//           label="Phone Number"
//           type="tel"
//           fullWidth
//           variant="outlined"
//           placeholder="Phone Number"
//         />
//         <TextField
//           required
//           margin="dense"
//           id="address"
//           name="user_address"
//           label="Street Address"
//           type="text"
//           fullWidth
//           variant="outlined"
//           placeholder="Street Address"
//         />
//         <TextField
//           required
//           margin="dense"
//           id="message"
//           name="message"
//           label="Message"
//           type="text"
//           fullWidth
//           variant="outlined"
//           placeholder="Your message.."
//         />
//         <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
//           <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={onCaptchaChange} />
//         </div>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleClose}>Cancel</Button>
//         <Button type="submit" disabled={!captchaVerified}>
//           Submit
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// }
