import footerLogo from "./images/On Scene Disaster Restorations LOGO Final Vectors-01.svg";
import floodedHouse from "./images/Flooded House.webp";
import exteriorFloodedHouse from "./images/Exterior House Flooded.webp";
import waterDropplet from "./images/WaterDroplet.webp";
import flame from "./images/Flame.webp";
import mold from "./images/Mold.webp";
import IICRCLogo from "./images/Temp IICRC.png";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import * as React from "react";
//these 3 imports are added temporarlly
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "./card";
import Card1x3 from "./card 1x3";
import AppHeader from "./AppHeader";
import { Button, Container, useMediaQuery } from "@mui/material";
import { Typography, useTheme } from "@mui/material";
import { Box } from "@mui/material";
import GoogleMapEmbed from "./GoogleMapEmbed";
import ContactForm from "./ContactForm";
import TimelineItem from "./TimeLineItem";
import { useState } from "react";
import houseFireDamage from "./images/fire-damage-house.jpg";
import houseWaterDamage from "./images/water-damage-house.jpg";
import houseMoldDamage from "./images/mold-damage-house.jpg";
import houseFloodDamage from "./images/flood-damage-house.jpg";

// Styled components using MUI
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
}));

function App() {
  const contactRef = React.useRef(null);

  const scrollToContact = () => {
    if (contactRef.current) {
      const topPosition = contactRef.current.offsetTop - 100; // Adjust 100px offset as needed
      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const reasons = [
    {
      title: "Local Expertise",
      description:
        "As a local business, we understand the specific needs and challenges of our community.",
    },
    {
      title: "Professional Integrity",
      description:
        "Owned and operated by firefighters and veterans, we bring reliability and dedication to every job.",
    },
    {
      title: "Exceptional Service",
      description:
        "We are committed to exceeding your expectations with our comprehensive disaster recovery solutions.",
    },
    {
      title: "Personal Commitment",
      description:
        "We focus on not just restoring buildings, but ensuring your home feels like home again.",
    },
    {
      title: "Experienced in Crisis",
      description:
        "With firsthand experience in disaster response, we know how to handle crises with precision and empathy.",
    },
  ];

  //toggle section
  const [expanded, setExpanded] = useState(null);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleExpand = (label) => {
    setExpanded(expanded === label ? null : label);
  };

  const cardsData = [
    {
      label: "Fire Damage",
      imageSrc: flame,
      title: "Fire",
      intro: "Quick response and expert service to mitigate fire and smoke damage.",
      steps: [
        "Assess the damage",
        "Remove soot and debris",
        "Deodorize and sanitize",
        "Restore and rebuild",
      ],
      assurance: "We guarantee a restored space with attention to detail and safety.",
      image: houseFireDamage,
      details:
        "Fire damage can result from various sources such as wildfires, electrical fires, and accidents. It is crucial to address fire damage promptly to prevent structural issues and health problems.",
    },
    {
      label: "Water Damage",
      imageSrc: waterDropplet,
      title: "Water",
      intro: "Effective solutions to handle floods and leaks, minimizing structural harm.",
      steps: [
        "Water extraction",
        "Drying out affected areas",
        "Cleaning and disinfecting",
        "Restoration of damaged property",
      ],
      assurance: "Your satisfaction with our thorough drying and restoration is guaranteed.",
      image: houseWaterDamage,
      details:
        "Water damage can occur due to flooding, leaks, or high humidity. It can lead to mold growth, structural deterioration, and other serious issues if not treated quickly.",
    },
    {
      label: "Mold Damage",
      imageSrc: mold,
      title: "Mold",
      intro: "Comprehensive mold remediation and prevention services.",
      steps: [
        "Inspect and identify affected areas",
        "Isolate the contaminated area",
        "Remove mold and affected materials",
        "Clean and sanitize",
        "Restore affected surfaces",
      ],
      assurance:
        "We ensure your home or workplace is mold-free and safe, preventing future outbreaks.",
      image: houseMoldDamage,
      details:
        "Mold thrives in moist environments and can spread quickly throughout a property, causing health issues and damaging surfaces and structures.",
    },
    {
      label: "Flood Damage",
      imageSrc: exteriorFloodedHouse,
      title: "Flood",
      intro: "Rapid flood damage restoration to minimize structural and health risks.",
      steps: [
        "Assess the extent of flood damage",
        "Extract standing water",
        "Dry and dehumidify affected areas",
        "Sanitize and disinfect surfaces",
        "Restore and rebuild damaged structures",
      ],
      assurance:
        "Our experienced team guarantees efficient restoration, minimizing downtime and ensuring safety.",
      image: houseFloodDamage,
      details:
        "Flood damage requires immediate attention to prevent lasting damage to the building structure, as well as to mitigate the risk of mold and mildew growth.",
    },
  ];

  return (
    <div className="App" id="Top">
      <AppHeader />
      <body>
        <section className="Banner">
          <h1 className="Banner-Text">Call Now!</h1>
          <a href="tel:+18434521638" className="Banner-no-style-anchor">
            <h1>(843) 452-1638</h1>
          </a>
        </section>
        <section className="Center-image">
          <img src={floodedHouse} alt="flooded house" />
        </section>
        <section className="Damage-Cover-Badges">
          <h2>Got Damage? We’ve Got You Covered!</h2>
          <Grid container>
            {cardsData.map((card, index) => (
              <React.Fragment key={index}>
                <Card
                  label={card.label}
                  imageSrc={card.imageSrc}
                  onClick={() => handleExpand(card.label)}
                />
                {isSmallScreen && expanded === card.label && (
                  <Box
                    sx={{
                      margin: 2,
                      padding: 3,
                      backgroundColor: "#f4f4f4",
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <h2 style={{ marginBottom: "8px" }}>{card.title} Restoration</h2>
                    <p style={{ marginBottom: "12px" }}>{card.intro}</p>
                    <img
                      src={card.image}
                      alt={`${card.title} damage`}
                      style={{
                        width: "100%",
                        maxHeight: "300px",
                        objectFit: "cover",
                        borderRadius: "4px",
                        marginBottom: "16px",
                      }}
                    />
                    <h3 style={{ marginBottom: "8px" }}>Restoration Process</h3>
                    <ul style={{ marginBottom: "16px", paddingLeft: "20px" }}>
                      {card.steps &&
                        card.steps.map((step, stepIndex) => (
                          <li key={stepIndex} style={{ marginBottom: "8px" }}>
                            {step}
                          </li>
                        ))}
                    </ul>
                    <p style={{ marginBottom: "16px" }}>{card.assurance}</p>
                    <Button
                      onClick={scrollToContact}
                      className="scroll-to-contact-button"
                      style={{
                        backgroundColor: "#007bff",
                        color: "#ffffff",
                        padding: "10px 20px",
                        borderRadius: "4px",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      Get Immediate Help
                    </Button>
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Grid>
          {!isSmallScreen && expanded && (
            <Box
              sx={{
                margin: 2,
                padding: 3,
                backgroundColor: "#f4f4f4",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {cardsData
                .filter((card) => card.label === expanded)
                .map((card) => (
                  <React.Fragment key={card.label}>
                    <h2 style={{ marginBottom: "12px" }}>{card.title} Restoration</h2>
                    <p style={{ marginBottom: "16px" }}>{card.intro}</p>
                    <img
                      src={card.image}
                      alt={`${card.title} damage`}
                      style={{
                        width: "100%",
                        maxHeight: "400px",
                        objectFit: "cover",
                        borderRadius: "4px",
                        marginBottom: "20px",
                      }}
                    />
                    <h3 style={{ marginBottom: "12px" }}>Restoration Process</h3>
                    <ul
                      className="no-bullets"
                      style={{ marginBottom: "20px", paddingLeft: "20px" }}
                    >
                      {card.steps &&
                        card.steps.map((step, index) => (
                          <li key={index} style={{ marginBottom: "8px" }}>
                            {step}
                          </li>
                        ))}
                    </ul>
                    <p style={{ marginBottom: "12px" }}>{card.details}</p>
                    <p style={{ marginBottom: "12px" }}>{card.assurance}</p>
                    <Button
                      onClick={scrollToContact}
                      className="scroll-to-contact-button"
                      style={{
                        backgroundColor: "#007bff",
                        color: "#ffffff",
                        padding: "10px 20px",
                        borderRadius: "4px",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      Get Immediate Help
                    </Button>
                  </React.Fragment>
                ))}
            </Box>
          )}
        </section>
        <Container maxWidth="lg">
          <Box
            sx={{
              my: 4, // margin top and bottom
              p: 3, // padding
              textAlign: "center",
              backgroundColor: "#fff", // white background
              borderRadius: "7px",
              boxShadow: "0 0 20px 10px #0d47a4", // Blue shadow
            }}
          >
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              WHY US
            </Typography>
            <Box sx={{ my: 2, position: "relative" }}>
              {/* Conditional rendering of the central line */}
              {matches && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: "50%",
                    width: "2px",
                    bgcolor: "divider",
                    transform: "translateX(-50%)",
                    zIndex: 0,
                  }}
                />
              )}
              {reasons.map((reason, index) => (
                <TimelineItem
                  key={index}
                  title={reason.title}
                  description={reason.description}
                  imageSrc={reason.imageSrc}
                  isOdd={index % 2 !== 0}
                />
              ))}
            </Box>
          </Box>
        </Container>
        <section className="Testimony">
          <section className="Testimony-Card">
            <h2>What They Are Saying ...</h2>
            <Grid container spacing={6}>
              <Card1x3
                text="I recently had the unfortunate experience of fire damage at my property, and On
                Scene Disaster Restoration was an absolute lifesaver. From the moment they arrived,
                their team was professional, compassionate, and incredibly efficient in handling the
                cleanup and restoration process. The attention to detail and dedication to restoring
                my home back to its original state was truly commendable. I'm deeply grateful to On
                Scene Disaster Restoration for their excellent service and support during such a
                stressful time."
                label="- James S."
              />
              <Card1x3
                text="I recently had the unfortunate experience of needing disaster restoration services, and I turned to On Scene Disaster Restoration for help. I must say, they exceeded all my expectations! Their team was incredibly responsive, arriving on the scene promptly and handling everything with the utmost professionalism and care. They not only restored my property efficiently but also guided me through the entire process, making a stressful situation much more manageable. I'm truly grateful for their exceptional service and highly recommend On Scene Disaster Restoration to anyone facing similar challenges."
                label="- Elena M."
              />
              <Card1x3
                text="After a recent flood wreaked havoc in our home, On Scene Disaster Restoration was a beacon of hope for us. Their team arrived promptly and handled everything with such professionalism and care, it truly eased our stress during such a difficult time. The quality of their work was outstanding, and they went above and beyond to ensure our home was restored to its pre-disaster condition. I cannot recommend On Scene Disaster Restoration enough for anyone facing similar disaster challenges; they truly live up to their name by being the support you need on the scene when it matters most."
                label="- Liam B."
              />
            </Grid>
          </section>
        </section>
        <section className="Map-Contact">
          <GoogleMapEmbed />
        </section>
        <section ref={contactRef} className="Contact-Us">
          <ContactForm />
        </section>
        <section className="Affiliates">
          <img src={IICRCLogo} className="IICRC-logo" alt="ICCRC logo" />
        </section>
      </body>
      <footer>
        <div>
          <img src={footerLogo} className="Footer-App-logo" alt="logo" />
        </div>
        <div>Copyright 2024 All Rights Reserved</div>
      </footer>
    </div>
  );
}

export default App;
